import React from 'react'
import Layout from "../components/layout"
import Helmet from 'react-helmet'

export default () => (

  <Layout>
    <Helmet>
      <title>How to use Joycards - step 3</title>
      <meta name="description" content="Share some joy with Joycards" />
    </Helmet>
    <section className='ht-wrapper'>
      <div className='container'>
        <div className='ht-screen flex flex-col flex-jc'>
          <div className='ht-counter'>
            <span>3 of 3</span>
          </div>
          <div className='ht-body flex flex-ac'>
            <div>
              <span className='heading-span'>How to</span>
              <h1>Step 3:</h1>
              <p class="how-text">When your card is ready, send it and share some joy <span role="img" aria-label="smiley face">😄</span>.</p>
            </div>
          </div>
          <div className='ht-foot'>
            <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='btn btn-teal btn-lg cta-btn'>Go to sign up</a>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
